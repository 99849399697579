<template>
  <div class="sub-header-thin-div">
    <div :class="isMobile ? 'm-2' : 'w-75 mx-auto my-25 d-flex flex-column'" v-if="myInfo">
      <div data-app class="p-5 box-white">
        <div class="center">
          <span class="font-25 font-bold">{{ $t('profile') }}</span>
        </div>
        <div class="mt-5 browse-image">
          <img class="my-image rounded-circle" :src="photoUrl ? photoUrl : assets.profile" />
          <input
            type="file"
            accept="image/*"
            v-on:change="onFileChanged"
            placeholder="Photo"
          />
        </div>
        <div class="mt-5 d-flex justify-content-center">
          <v-btn class="button-empty" @click="deletePhoto()">
            <span class="px-10">{{ $t('delete_photo') }}</span>
          </v-btn>
        </div>
        <v-dialog v-model="visibleCropDialog" width="800px">
          <v-card class="box-white">
            <Cropper
              class="m-5"
              ref="cropper"
              :style="`height: ${isMobile ? '400' : '600'}px;`"
              :src="selectedPhoto"
              :stencil-props="{
                aspectRatio: 1/1
              }"
            />
            <div class="d-flex justify-content-end">
              <v-btn class="button-normal" @click="cropDone()">
                <span class="px-5">OK</span>
              </v-btn>
              <v-btn class="ml-5 button-normal" @click="visibleCropDialog = false;">
                <span class="px-5">{{ $t('cancel') }}</span>
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="myInfo.email && !myInfo.email.endsWith(SUFFIX_SOCIAL_WITHOUT_EMAIL)">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('email') }}</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="myInfo.email" disabled />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('phone') }}</span>
          <VuePhoneNumberInput
            :class="isMobile ? 'mt-1' : 'w-75'"
            style="z-index: 10;"
            color="#CCCCCC"
            error-color="#F02121"
            valid-color="#008FC7"
            :fetch-country="true"
            :translations="phoneNumberInputTranslations"
            :disabled="!myInfo.email"
            v-model="phone"
            @update="onPhoneNumberUpdated"
            v-if="phone" />
          <VuePhoneNumberInput
            :class="isMobile ? 'mt-1' : 'w-75'"
            style="z-index: 10;"
            default-country-code="DE"
            color="#CCCCCC"
            error-color="#F02121"
            valid-color="#CCCCCC"
            :translations="phoneNumberInputTranslations"
            :disabled="!myInfo.email"
            v-model="phone"
            @update="onPhoneNumberUpdated"
            v-else />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('username') }}</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="userName" maxlength="40" oninput="this.value = this.value.replace(/[^0-9a-zA-Z\S]/g,'');" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('birthday') }}</span>
          <div :class="isMobile ? 'mt-1' : 'w-75'">
            <DatePicker
              :class="`${isMobile ? 'mt-1 w-100' : ''}`"
              v-model="datePicker"
              format="YYYY-MM-DD"
              type="date" />
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('gender') }}</span>
          <b-form-radio-group
            :class="isMobile ? 'mt-1' : 'w-75'"
            buttons
            button-variant="outline-info"
            v-model="gender"
            :options="genderOptions"
          />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('which_music_do_you_like') }}</span>
          <div :class="isMobile ? 'mt-1' : 'w-75'">
            <div class="row">
              <div class="col-xl-4 col-6" v-for="(item, index) in genres" :key="(item, index)">
                <div :class="`my-1 genre-${item.name === genre ? 'selected' : 'normal'} vertical-center`" @click="genre = item.name">
                  <img :src="item.icon" :height="15" />
                  <span class="ml-5 font-12">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 d-flex flex-column" v-if="myInfo.userType === USER_ORGANIZER && organizerInfo">
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('description') }}</span>
            <textarea :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.description" rows="5" />
          </div>
          <div class="mt-10 d-flex justify-content-end">
            <v-btn class="button-normal" @click="getLikeListTask()">
              <span class="px-10">{{ $t('export_followers') }}</span>
            </v-btn>
          </div>
          <div class="my-15 mx-auto w-50 div-divider-h" />
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('organizer_name') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.name" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('tax_code') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.taxCode" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">UST ID</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.ustId" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('address') }}</span>
            <gmap-autocomplete :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" :value="organizerInfo.address" placeholder="" @place_changed="onPlaceChanged" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('phone') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.phone" />
          </div>
          <span class="mt-5 font-label">{{ $t('desc_organizer_bank_account') }}</span>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('iban_number') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="iban" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('account_holder_name') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.cardName" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="isTurkeyIBAN">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">Swift Code</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.swiftCode" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-else>
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('bic') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="organizerInfo.cardBIC" />
          </div>
        </div>
        <div class="mt-5 d-flex justify-content-end">
          <v-btn class="button-normal" @click="updateUserTask()">
            <span class="px-10">{{ $t('save') }}</span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Reducer from 'image-blob-reduce';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import parsePhoneNumber from 'libphonenumber-js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { httpsCallable } from 'firebase/functions';
import { getDocs, query, collection, where } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { functions, firestore } from '../../../main';
import { getCurDateString, getDateStringFromDate, isValidName, showLoading, showFunctionError } from '../../../functions';

import profile from '@/assets/images/profile.png';
import genre_hiphop_rap from '@/assets/images/genre_hiphop_rap.png';
import genre_rock from '@/assets/images/genre_rock.png';
import genre_pop from '@/assets/images/genre_pop.png';
import genre_electro from '@/assets/images/genre_electro.png';
import genre_dj_dance from '@/assets/images/genre_dj_dance.png';

const IBAN = require('iban');

export default {
  name: 'Register',
  components: {
    DatePicker,
    VuePhoneNumberInput,
    Cropper
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  watch: {
    iban() {
      if (this.organizerInfo) {
        this.organizerInfo.cardNumber = this.iban;
        if (IBAN.isValid(this.iban)) {
          this.getBICTask();
        }
      }
    }
  },
  data() {
    return {
      phoneNumberInputTranslations: {
        countrySelectorLabel: this.$t('country_code'),
        phoneNumberLabel: this.$t('phone'),
        example: this.$t('example')
      },
      assets: {
        profile
      },
      genres: [
        {
          icon: genre_hiphop_rap,
          name: 'HipHop/Rap'
        },
        {
          icon: genre_rock,
          name: 'Rock'
        },
        {
          icon: genre_pop,
          name: 'Pop'
        },
        {
          icon: genre_electro,
          name: 'Electro'
        },
        {
          icon: genre_dj_dance,
          name: 'DJ/Dance'
        },
      ],
      phone: '',
      selectedPhoto: '',
      cropResult: null,
      photoUrl: '',      
      visibleCropDialog: false,
      showCrop: false,
      isTurkeyIBAN: false,
      datePicker: null,
      genderOptions: [
        { text: this.$t('undefined'), value: 0 },
        { text: this.$t('male'), value: 1 },
        { text: this.$t('female'), value: 2 }
      ],
      gender: 0,
      userName: '',
      genre: 0,
      organizerInfo: null,
      iban: ''
    };
  },
  mounted() {
    if (this.myInfo) {
      if (this.myInfo.photoUrl) {
        this.photoUrl = this.myInfo.photoUrl;
      }
      this.phone = this.myInfo.phone;
      if (this.myInfo.birthday) {
        this.datePicker = new Date(`${this.myInfo.birthday}T23:59:59`);
      }
      this.gender = this.myInfo.gender;
      this.phone = this.myInfo.phone;
      this.userName = this.myInfo.userName;
      this.genre = this.myInfo.genre;
      if (this.myInfo.organizerInfo) {
        this.organizerInfo = this.myInfo.organizerInfo;
        this.iban = this.myInfo.organizerInfo.cardNumber;
      }
    } else {
      this.$router.push(`/`);
    }
  },
  methods: {
    onFileChanged(event) {
      if (event.target.files && event.target.files.length > 0) {
        const params = {
          max: 1300,
          unsharpAmount: 80,
          unsharpRadius: 0.6,
          unsharpThreshold: 2,
        };
        Reducer().toBlob(event.target.files[0], params).then(blob => {
          this.selectedPhoto = URL.createObjectURL(blob);
          this.visibleCropDialog = true;
        });
      }
    },
    cropDone() {
      this.visibleCropDialog = false
      const result = this.$refs.cropper.getResult();
      if (result) {
        const cropResult = result.canvas.toDataURL('image/jpeg');
        if (cropResult) {
          this.photoUrl = cropResult;
          this.cropResult = cropResult;
        }
      }
    },
    onPlaceChanged(placeData) {
      if (this.organizerInfo && placeData.formatted_address) {
        this.organizerInfo.address = placeData.formatted_address;
      }
    },
    onPhoneNumberUpdated(data) {
      if (data.isValid && data.formattedNumber) {
        this.phone = data.formattedNumber;
      } else {
        this.phone = '';
      }
    },
    deletePhoto() {
      this.cropResult = null;
      this.photoUrl = '';
    },
    exportFollowers(followerList) {
      if (followerList.length < 1) {
        return;
      }
      var csvContent = 'data:text/csv;charset=utf-8,Name,Email,Phone';
      followerList.forEach(info => {
        const phoneNumber = info.phone ? parsePhoneNumber(info.phone, 'DE') : null;
        csvContent += `\n${info.name},${info.email},${phoneNumber ? phoneNumber.formatInternational() : ''}`;
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${this.myInfo.userName}_Followers_${getCurDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    getBICTask() {
      axios.get(`https://openiban.com/validate/${this.iban}?getBIC=true`).then(response => {
        const bankData = response.data.bankData;
        if (bankData) {
          if (bankData.name) {
            this.organizerInfo.cardName = bankData.name;
          }
          if (bankData && bankData.bic) {
            this.organizerInfo.cardBIC = bankData.bic;
          }
        }
        this.isTurkeyIBAN = this.organizerInfo.cardNumber.startsWith('TR');
      });
    },
    uploadPhotoTask() {
      const metadata = { contentType: 'image/jpeg' };
      const storage = getStorage();
      const storageRef = ref(storage, `UserPhotos/${getCurDateString('YYYY-MM-DD')}/${getCurDateString()}_${Date.now()}.jpg`);
      const loader = showLoading(this, 1);
      uploadString(storageRef, this.cropResult, 'data_url', metadata).then(snapshot => {
        getDownloadURL(snapshot.ref).then(downloadURL => {
          loader.hide();
          this.photoUrl = downloadURL;
          this.cropResult = '';
          this.updateUserTask();
        });
      }).catch(error => {
        loader.hide();
        this.$toast.error(this.$t('alert_unexpected_error'));
      });
    },
    updateUserTask() {
      if (!this.cropResult && !this.photoUrl) {
        this.$toast.error(this.$t('alert_select_photo'));
        return;
      }
      if (this.userName.length < 4) {
        this.$toast.error(this.$t('alert_min_characters', [4, this.$t('username')]));
        return;
      }
      if (!isValidName(this.userName)) {
        this.$toast.error(this.$t('alert_special_character_not_allowed'));
        return;
      }
      const birthday = this.datePicker ? getDateStringFromDate(this.datePicker, 'YYYY-MM-DD') : '';
      if (!birthday) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('birthday')]));
        return;
      }
      if (!this.genre) {
        this.$toast.error(this.$t('which_music_do_you_like'));
        return;
      }
      if (this.cropResult && !this.photoUrl.startsWith('https://firebasestorage.googleapis.com/v0/b/eventbox-c7c8f.appspot.com/o/UserPhotos')) {
        this.uploadPhotoTask();
        return;
      }
      const params = {
        editProfile: true,
        userId: this.$store.state.myId,
        phone: this.phone,
        userName: this.userName,
        photoUrl: this.photoUrl,
        genre: this.genre,
        gender: this.gender,
        birthday: birthday
      };
      if (this.myInfo.userType === this.USER_ORGANIZER && this.organizerInfo) {
        /*if (this.organizerInfo.cardNumber) {
          if (!IBAN.isValid(this.organizerInfo.cardNumber)) {
            this.$toast.error(this.$t('alert_invalid_iban_number'));
            return;
          }
        }*/
        if (this.isTurkeyIBAN) {
          this.organizerInfo.cardBIC = '';
        } else {
          this.organizerInfo.swiftCode = '';
        }
        params['editOrganizer'] = true;
        params['organizerInfo'] = this.organizerInfo;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'updateUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('alert_success'));
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    getLikeListTask() {
      const loader = showLoading(this);
      const q = query(collection(firestore, 'like'), where('targetType', '==', 1), where('targetId', '==', this.$store.state.myId));
      getDocs(q).then(querySnapshot => {
        const likeList = [];
        querySnapshot.forEach(doc => likeList.push(doc.data()));
        if (likeList.length > 0) {
          this.getUserListTask(likeList, loader);
        } else {
          loader.hide();
        }
      });
    },
    getUserListTask(likeList, loader) {
      const promises = [];
      var idList = [];
      likeList.forEach(info => {
        if (!idList.includes(info.userId)) {
          idList.push(info.userId);
        }
        if (idList.length === 10) {
          promises.push(getDocs(query(collection(firestore, 'user'), where('userId', 'in', idList))));
          idList = [];
        }
      });
      if (idList.length > 0) {
        promises.push(getDocs(query(collection(firestore, 'user'), where('userId', 'in', idList))));
      }
      Promise.all(promises).then(snapshots => {
        loader.hide();
        const followerList = [];
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            followerList.push({
              name: doc.get('userName'),
              email: doc.get('email'),
              phone: doc.get('phone')
            });
          }
        }
        this.exportFollowers(followerList);
      });
    }
  }
};
</script>